import PlayerControl from "@/components/playerControl"; // Ensure correct import path
import { ReplayCircleFilledOutlined } from "@mui/icons-material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";

const VideoPlayer = () => {
  const { video } = useParams();
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef(null);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        // Ensure event.data is a string
        const data = event?.data;
        console.log({
          data,
        });

        if (typeof data === "string") {
          // Parse JSON message
          const message = JSON.parse(data);
          console.log({
            message,
          });

          if (!message?.mobile) {
            return;
          }

          // Check if message contains the expected properties
          if (message?.event) {
            switch (message.event) {
              case "play":
                setPlaying(true);
                break;
              case "pause":
                setPlaying(false);
                break;
              default:
                console.warn("Unhandled event type:", message.event);
                break;
            }
          } else {
            console.warn("Message does not contain 'event' property:", message);
          }
        } else {
          console.warn("Event data is not a string:", data);
        }
      } catch (error) {
        // Log any parsing errors
        // console.error("Error parsing message", error);
      }
    };

    document.addEventListener("message", handleMessage); // For Android
    window.addEventListener("message", handleMessage); // For iOS

    return () => {
      document.removeEventListener("message", handleMessage);
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const videoURL = useMemo(() => {
    return `https://${process.env.REACT_APP_CLOUD}/exercises/videos/${video}`;
  }, [video]);

  const isReplayVisible = useMemo(() => {
    if (isPortrait) {
      return played === 1;
    }
    return false;
  }, [isPortrait, played]);

  const handleResize = () => {
    setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
  };

  useEffect(() => {
    handleResize(); // Set initial orientation
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSeek = (value) => {
    setPlayed(value / duration);
    playerRef.current.seekTo(value / duration);
    setPlaying(true);
  };

  const handleVolumeChange = (value) => {
    setVolume(value);
  };

  const handleProgress = (state) => {
    if (!state.seeking) {
      setPlayed(state.played);
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleReplay = () => {
    playerRef?.current?.seekTo(0);
    setPlaying(true);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);

    const eventData = JSON.stringify(
      playing
        ? { event: "pause", played }
        : {
            event: "play",
            played,
          }
    );
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(eventData);
      return;
    }
    window.postMessage(eventData);
  };

  const handlePlay = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ event: "play", played })
      );
      return;
    }
    window.postMessage(JSON.stringify({ event: "play", played }));
  };

  const handlePause = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ event: "play", played })
      );
      return;
    }
    window.postMessage(JSON.stringify({ event: "play", played }));
  };

  const handleEnded = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "ended" }));
      return;
    }
    window.postMessage(JSON.stringify({ event: "ended" }));
  };

  const handleLoaded = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ event: "loaded", played })
      );
      return;
    }
    window.postMessage(JSON.stringify({ event: "loaded", played }));
  };

  const handleError = (error, data, hlsInstance, hlsGlobal) => {
    //
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <ReactPlayer
        ref={playerRef}
        url={videoURL}
        playing={playing}
        controls={!isPortrait}
        width="100%"
        height="100%"
        style={{
          transform: isPortrait ? "scale(2)" : "scale(1)",
          transformOrigin: "center center",
        }}
        playsinline={true}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onReady={handleLoaded}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onError={handleError}
      />
      {isPortrait && (
        <PlayerControl
          playing={playing}
          onPlayPause={handlePlayPause}
          onSeek={handleSeek}
          onVolumeChange={handleVolumeChange}
          duration={duration}
          played={played}
          volume={volume}
        />
      )}
      {isReplayVisible && (
        <div className="replay-button" onClick={handleReplay}>
          <ReplayCircleFilledOutlined />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
