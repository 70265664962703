import React, { useMemo } from "react";
import { VideoSeekSlider } from "react-video-seek-slider";
import "react-video-seek-slider/styles.css";
import "./playerControlSlider.css";

import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { IconButton } from "@mui/material";
// import VolumeOffIcon from "@mui/icons-material/VolumeOff";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import CustomInputSlider from "../customInputSlider";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
};

const PlayerControl = ({
  playing,
  onPlayPause,
  onSeek,
  // onVolumeChange,
  duration,
  played,
  // volume,
}) => {
  // const [isVolumeSliderVisible, setVolumeSliderVisible] = useState(false);

  const currentTime = useMemo(() => {
    return duration * played;
  }, [duration, played]);

  const isEnded = useMemo(() => {
    return currentTime >= duration;
  }, [currentTime, duration]);

  // const toggleVolumeSlider = () => {
  //   setVolumeSliderVisible(!isVolumeSliderVisible);
  // };

  // const handleOnVolumeChange = (e) => {
  //   onVolumeChange(parseFloat(e.target.value));
  // setTimeout(() => {
  //   setVolumeSliderVisible(false);
  // }, 3000);
  // };

  const renderPlayButton = () => {
    return (
      <IconButton onClick={onPlayPause} style={styles.button} size="medium">
        {playing && !isEnded ? (
          <PauseIcon style={styles.icon} />
        ) : (
          <PlayCircleFilledWhiteIcon style={styles.icon} />
        )}
      </IconButton>
    );
  };

  // const renderVolume = () => {
  //   return (
  //     <IconButton
  //       onClick={toggleVolumeSlider}
  //       style={styles.button}
  //       size="medium"
  //     >
  //       {volume ? (
  //         <VolumeUpIcon style={styles.icon} />
  //       ) : (
  //         <VolumeOffIcon style={styles.icon} />
  //       )}
  //     </IconButton>
  //   );
  // };

  const renderTimeControl = () => {
    return (
      <div style={styles.seekSliderContainer}>
        <VideoSeekSlider
          max={duration}
          currentTime={currentTime}
          onChange={onSeek}
          progress={played * 100}
          description=""
          limitTimeTooltipBySides={false}
        />
      </div>
    );
  };

  const renderCurrentTimeSlider = () => {
    return (
      <span style={styles.time}>
        {formatTime(duration * played)} / {formatTime(duration)}
      </span>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.controls}>
        {renderPlayButton()}
        {/* {renderVolume()} */}
        {renderTimeControl()}
        {renderCurrentTimeSlider()}
      </div>
      {/* {isVolumeSliderVisible && (
        <CustomInputSlider
          type="range"
          min={0}
          max={1}
          step="any"
          value={volume}
          onChange={handleOnVolumeChange}
          style={styles.verticalSlider}
          orient="vertical"
        />
      )} */}
    </div>
  );
};

export default PlayerControl;

const styles = {
  container: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    padding: "10px 0",
  },
  controls: {
    display: "flex",
    width: "100%",
    height: 64,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    color: "#fff",
    cursor: "pointer",
    width: 48,
    height: 48,
    justifyContent: "center",
    alignItems: "center",
    margin: 8,
  },
  icon: {
    fontSize: 28,
  },
  verticalSlider: {
    position: "absolute",
    left: "80px",
    bottom: "64px",
    width: "10px",
    height: "100px",
    margin: "0 10px",
    writingMode: "tb-lr",
    appearance: "slider-vertical",
    webkitAppearance: "slider-vertical",
  },
  time: {
    color: "#fff",
    margin: "0 10px",
  },
  seekSliderContainer: {
    flexGrow: 1,
    height: "16px",
    marginLeft: "6px",
    justifyContent: "center",
  },
  replayIconContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "40px",
    color: "#fff",
    cursor: "pointer",
  },
};
