import React from "react";
import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Bộ sưu tập</h2>
          <p>
            Khám phá bộ sưu tập ảnh độc đáo của chúng tôi, nơi những khoảnh khắc
            trở nên sống động. Đắm chìm vào một hành trình trực quan, nói lên sự
            tận tụy của chúng tôi đối với sự xuất sắc.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
