import axios from "axios";

export const requestDeleteAccountApi = async ({ email }) => {
  return axios({
    method: "post",
    url: "https://asia-southeast1-fitlive-dev.cloudfunctions.net/requestDeleteAccount",
    data: {
      data: {
        email,
      },
    },
  });
};

export const confirmRequestDeleteAccountApi = ({ otp, email }) => {
  return axios({
    method: "post",
    url: "https://asia-southeast1-fitlive-dev.cloudfunctions.net/confirmRequestDeleteAccount",
    data: {
      data: {
        otp,
        email,
      },
    },
  });
};
