import React from "react";
import "./App.css";

import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import SmoothScroll from "smooth-scroll";

import { Navigation } from "@/components/navigation";
import { Home } from "@/pages/home";
import { Policies } from "@/pages/policies";
import { Terms } from "@/pages/terms";
import RequestDeleteAccount from "./pages/delete-account";
import VideoPlayer from "./pages/player";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Navigation />
        <Outlet />
      </div>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/privacy-policies",
        element: <Policies />,
      },
      {
        path: "/terms-of-use",
        element: <Terms />,
      },
      {
        path: "/delete-account",
        element: <RequestDeleteAccount />,
      },
      {
        path: "/exercises/:video",
        element: <VideoPlayer />,
      },
    ],
  },
]);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
