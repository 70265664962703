import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Dịch vụ của chúng tôi</h2>
          <p>
            Bước vào một thế giới nơi sức khỏe gặp gỡ lòng quyết tâm. Tại trung
            tâm thể dục của chúng tôi, chúng tôi tận tâm tạo dựng không chỉ là
            các cơ thể mà còn là lối sống.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
