import { confirmRequestDeleteAccountApi, requestDeleteAccountApi } from "@/api";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./delete-account.css";

function RequestDeleteAccount() {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleRequestAccountDeletion = async () => {
    try {
      const response = await requestDeleteAccountApi({ email });
      // console.log(response.data);
      if (response?.data?.result?.success) {
        setPopupTitle("Yêu cầu xoá tài khoản!");
        setPopupMessage(response?.data?.result?.message ?? "");
        setShowPopup(true);
      }
      setRequestSent(true);
    } catch (error) {
      console.error("Error requesting account deletion:", error);
    }
  };

  const handleConfirmRequestAccountDeletion = async () => {
    try {
      const response = await confirmRequestDeleteAccountApi({ email, otp });
      // console.log(response.data);
      if (response?.data?.result?.success) {
        setPopupTitle("Xác nhận yêu cầu xoá tài khoản!");
        setPopupMessage(response?.data?.result?.message ?? "");
        setShowPopup(true);
        setIsFinished(true);
      }
      setRequestSent(true);
    } catch (error) {
      console.error("Error requesting account deletion:", error);
    }
  };

  const handleClosePopup = () => {
    setPopupTitle("");
    setPopupMessage("");
    setShowPopup(false);
  };

  if (isFinished) {
    return (
      <div className="centered-container">
        <h4 className="finished-message">
          Tài khoản của bạn sẽ được xoá sau 30 ngày, bạn có thể mở ứng dụng và
          đồng ý huỷ yêu cầu xoá tài khoản nếu muốn.
        </h4>
        <button className="home-button" onClick={handleNavigateHome}>
          Về Trang Chủ
        </button>
      </div>
    );
  }

  return (
    <div className="delete-account-container">
      <h1>Account Deletion</h1>
      <div className="delete-account-form">
        {!requestSent ? (
          <div>
            <h6 className="warning-text">
              Quyết định xoá tài khoản của bạn là một hành động quan trọng và
              không thể phục hồi. Bạn có 30 ngày để xem xét huỷ bỏ xoá tài khoản
              khi đăng nhập lại FitLiveVN.
            </h6>
            <br />
            <input
              type="email"
              placeholder="Nhập email"
              value={email}
              onChange={handleEmailChange}
            />
            <button onClick={handleRequestAccountDeletion} disabled={!email}>
              Yêu cầu xoá tài khoản
            </button>
          </div>
        ) : (
          <div>
            <h6 className="warning-text">
              Mã xác nhận đã được gửi đến hòm thư của bạn. Vui lòng nhập mã xác
              nhận để hoàn thành yêu cầu xoá tài khoản.
            </h6>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOTPChange}
            />
            <button onClick={handleConfirmRequestAccountDeletion}>
              Xác nhận xoá
            </button>
          </div>
        )}
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>{popupTitle}</h3>
            <p>{popupMessage}</p>
            <button className="popup-button-close" onClick={handleClosePopup}>
              Đóng
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestDeleteAccount;
