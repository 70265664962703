import React from "react";

import "@/css/dropdown.css";
import { Link, useLocation } from "react-router-dom";

export const Navigation = () => {
  const location = useLocation();
  const hideNavigationPaths = ["/exercises"];

  const shouldHideNavigation = hideNavigationPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  if (shouldHideNavigation) {
    return <div />;
  }

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            FitLive VN
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link
                to={{ pathname: "/", hash: "features" }}
                className="page-scroll"
              >
                Nổi bật
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/", hash: "about" }}
                className="page-scroll"
              >
                Về chúng tôi
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/", hash: "services" }}
                className="page-scroll"
              >
                Dịch vụ
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/", hash: "portfolio" }}
                className="page-scroll"
              >
                Bộ sưu tập
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/", hash: "testimonials" }}
                className="page-scroll"
              >
                Đánh giá
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/", hash: "team" }}
                className="page-scroll"
              >
                Đội ngũ
              </Link>
            </li>
            <li>
              <Link
                to={{ pathname: "/", hash: "contact" }}
                className="page-scroll"
              >
                Liên hệ
              </Link>
            </li>
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Điều khoản và chính sách <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/privacy-policies">Chính sách riêng tư</Link>
                </li>
                <li>
                  <Link to="/terms-of-use">Điều khoản sử dụng</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
