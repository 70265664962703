import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Nhóm thành viên</h2>
          <p>
            Đội ngũ của chúng tôi bao gồm các chuyên gia thể hình tận tâm cam
            kết đến sự thành công của bạn. Với các chương trình được tùy chỉnh
            và sự hướng dẫn chuyên nghiệp, chúng tôi luôn ở đây để hỗ trợ hành
            trình của bạn đến sức khỏe và hạnh phúc.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
