import React from "react";

import { Team } from "@/components/Team";
import { About } from "@/components/about";
import { Contact } from "@/components/contact";
import { Features } from "@/components/features";
import { Gallery } from "@/components/gallery";
import { Header } from "@/components/header";
import ScrollToHashElement from "@/components/scrollHash";
import { Services } from "@/components/services";
import { Testimonials } from "@/components/testimonials";
import landingPageData from "@/data/data.json";

export const Home = () => {
  return (
    <div>
      <ScrollToHashElement />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
